class AppURL {
  static BaseURL = "https://studymbbsinrostov.com/admin/api/"
  static VisitorDetails = this.BaseURL + "getvisitor"
  static GetNews = this.BaseURL + "news"
  static PostContact = this.BaseURL + "contact"
  static GetContactUs = this.BaseURL + "contactus"
  static GetContactUsText = this.BaseURL + "contactustext"
  static GetSocialLinks = this.BaseURL + "getsociallinks"
  static GetLogo = this.BaseURL + "logo"
  static GetFavicon = this.BaseURL + "favicon"
  static GetSlider = this.BaseURL + "allslider"
  static GetHomeAbout = this.BaseURL + "homeabout"
  static GetHomeServices = this.BaseURL + "homeservices"
  static GetHomeFeatures = this.BaseURL + "homefeatures"
  static GetHomeGallery = this.BaseURL + "gallery"
  static GetHomeClients = this.BaseURL + "clients"
  static GetHomeTestimonial = this.BaseURL + "testimonial"
  static GetHomeBrandLogos = this.BaseURL + "brandlogos"
  static GetHomeVideo = this.BaseURL + "video"
  static GetSuccessProfile = this.BaseURL + "successprofile"
  static GetHomeBlog = this.BaseURL + "blog"
  static GetHomeLatestNews = this.BaseURL + "latestnews"
  static GetHomeFaqs = this.BaseURL + "homefaqs"
  static GetPageMenu = this.BaseURL + "pagemenu"
  static GetMenu = this.BaseURL + "menu"
  static GetHomeTeam = this.BaseURL + "team"
  static GetDownloads = this.BaseURL + "downloads"
  static PostBlogComments = this.BaseURL + "comments"
  static PostSubscribe = this.BaseURL + "subscribe"
  static GetWhatsappLimit = this.BaseURL + "whatsapplimit"
  static GetEmailLimit = this.BaseURL + "emaillimit"
  static GetPhoneLimit = this.BaseURL + "phonelimit"
  static GetContactUsLimit = this.BaseURL + "contactuslimit"
  static GetBlinkingText = this.BaseURL + "blinking"
  static GetFaqs = this.BaseURL + "faqs"
  static GetOther = this.BaseURL + "other"
  static HomeContact = this.BaseURL + "homecontact"

  static GetBlog(blog_url) {

    return this.BaseURL + "blog/" + blog_url;

  }


  static BlogCommentsList(blog_url) {

    return this.BaseURL + "comments/" + blog_url;

  }

  static GetJavaScript(javascript_position) {

    return this.BaseURL + "javascript/" + javascript_position;

  }

  static GetHeadings(block_name) {

    return this.BaseURL + "headings/" + block_name;

  }

  static GetBlockImages(block_name) {

    return this.BaseURL + "blockimages/" + block_name;

  }

  static GetBreadcrumbs(page_name) {

    return this.BaseURL + "breadcrumbs/" + page_name;

  }


  static GetPage(page_url) {

    return this.BaseURL + "page/" + page_url;

  }

  static GetPageGallery(page_url) {

    return this.BaseURL + "pagegallery/" + page_url;

  }



  static GetPageVideo(code) {

    return this.BaseURL + "pagevideo/" + code;

  }

  static GetPageDownloads(code) {

    return this.BaseURL + "pagedownloads/" + code;

  }

  static GetPageFaqs(code) {

    return this.BaseURL + "pagefaqs/" + code;

  }

  static GetPageFeatures(code) {

    return this.BaseURL + "pagefeatures/" + code;

  }

  static GetPageContact(code) {

    return this.BaseURL + "pagecontact/" + code;

  }

  static GetPageServices(code) {

    return this.BaseURL + "pageservices/" + code;

  }

  static GetSeo(code) {

    return this.BaseURL + "seo/" + code;

  }

  static GetStaticSeo(code) {

    return this.BaseURL + "staticseo/" + code;

  }

  static GetRating(code) {

    return this.BaseURL + "rating/" + code;

  }

  static GetOffers(code) {

    return this.BaseURL + "offers/" + code;

  }

  static GetWhatsapp(contact_title) {

    return this.BaseURL + "whatsapp/" + contact_title;

  }


  static GetPhone(contact_title) {

    return this.BaseURL + "phone/" + contact_title;

  }



  static GetEmail(contact_title) {

    return this.BaseURL + "email/" + contact_title;

  }

  static GetUniversities(university_type) {

    return this.BaseURL + "universities/" + university_type;

  }





}

export default AppURL